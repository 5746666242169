
import { View } from 'client-website-ts-library/plugins';
import { Office, Profile } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { Component, Mixins } from 'vue-property-decorator';
import { ProfileFilter } from 'client-website-ts-library/filters';

import SideBySide from '@/components/Layout/SideBySide.vue';
import ProfileCards from '@/components/ProfileCards.vue';
import ListingSearch from '@/components/ListingSearch.vue';

@Component({
  components: {
    ListingSearch,
    SideBySide,
    ProfileCards,
  },
})
export default class About extends Mixins(View) {
  private office: Office | null = null;

  private profiles: Profile[] = [];

  private profilesInnisfail: Profile[] = [];

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });

    const filter = new ProfileFilter();

    API.Profiles.Search(filter, true).then((profiles) => {
      this.profiles = profiles.filter((profile) => profile.Id !== 'e7548efa-48ee-48ae-a674-581c0f917afb');
      this.profilesInnisfail = profiles.filter((profile) => profile.Id === 'e7548efa-48ee-48ae-a674-581c0f917afb');
    });
  }
}
